@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.container {
  display: inline-flex;
  align-items: center;
  border-radius: 18px;
  padding: 4px 10px;
  @include customizableText(700, 16px, 20px);
  align-self: flex-start;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.green {
  background-color: $success-200;
  color: $success-600;

  .dot {
    background-color: $success-600;
  }
}

.yellow {
  background-color: $warning-100;
  color: $warning-600;

  .dot {
    background-color: $warning-600;
  }
}

.red {
  background-color: $danger-100;
  color: $danger-500;

  .dot {
    background-color: $danger-500;
  }
}

.grey {
  background-color: $gray-200;
  color: $gray-400;

  .dot {
    background-color: $gray-400;
  }
}
