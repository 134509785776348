@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.spinner {
  border-radius: 50%;
  animation: spin 1.3s linear infinite;
}

.spinner.small {
  width: 25px;
  height: 25px;
}

.spinner.large {
  width: 50px;
  height: 50px;
}

.spinner.dark {
  border: 5px solid rgba(0, 0, 0, 0.3);
  border-top: 5px solid #000000;
}

.spinner.light {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #ffffff;
}

.spinner.danger {
  border: 5px solid rgba(255, 0, 68, 0.3);
  border-top: 5px solid #FF0044;
}

.spinner.blue {
  border: 8px solid rgba(71, 76, 158, 0.3);
  border-top: 8px solid #474C9E;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-alternative {
  position: relative;
  width: 40px;
  height: 40px;
  animation: rotate 6s linear infinite;

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(calc(18deg * var(--i)));

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: rgba(71, 76, 158, 0.3);
      box-shadow: 0 0 2px rgba(71, 76, 158, 0.3),
      0 0 3px rgba(71, 76, 158, 0.3),
      0 0 4px rgba(71, 76, 158, 0.3);
      transform: scale(0);
      animation: animate 2s linear infinite;
      animation-delay: calc(0.1s * var(--i));
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: scale(1);
  }
  80%, 100% {
   transform: scale(0);
  }
}

