@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.order {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    @include mobile {
      gap: 20px;
      margin-bottom: 40px;
    }
  
    &__block {
      background-color: $gray-100;
      border-radius: 20px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include mobile {
        padding: 16px;
      }
    }
  
    &__label {
      @include customizableText(700, 16px, 20px);
      color: $primary-500;
      margin-bottom: 10px;

      @include mobile {
        @include customizableText(700, 18px, 22px);
      }
    }
  
    &__date {
      @include customizableText(500, 16px, 20px);
      color: $gray-500;

      @include mobile {
        @include customizableText(500, 18px, 22px);
      }
    }
  }
