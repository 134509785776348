@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.card {
  display: flex;
  flex-direction: row;
  height: auto;
  max-width: 100%;
  align-items: center;
  overflow: hidden;
  width: 100%;

  .image {
    margin: 0;
    margin-right: 20px;
    width: 100px;
    height: 100px;
    padding: 18px 16px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}