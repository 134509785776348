@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.content {
  width: 980px;

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50dvh;
  }
}

.show-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 80px;

  width: 100%;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 100%;
  padding: 50px 0;
  background-color: $gray-50;
  margin-bottom: 80px;

  &__text {
    margin-bottom: 30px;
    @include customizableText(600, 20px, 24px);
    color: $textBlack;
    text-align: center;
  }
}