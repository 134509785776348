@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $primary-100;
  color: white;
  padding: 6px 36.5px;
  border-radius: 10px;
  width: 200px;
  height: 32px;
  @include customizableText(700, 16px, 19.5px);
  z-index: 12;
}