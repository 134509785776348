// Primary colors
$primary-25: #E9E9F6;
$primary-50: #C1C4D6;
$primary-100: #7F81A0;
$primary-200: #535577;
$primary-300: #3C3F60;
$primary-400: #282A4B;
$primary-500: #191B38;
$primary-600: #14162D;
$primary-700: #0F1022;
$primary-800: #0D0E1C;
$primary-900:#070811;

// Secondary colors
$secondary-25: #F6E6F0;
$secondary-50: #EDCCE2;
$secondary-100: #E4B3D3;
$secondary-200: #D280B6;
$secondary-300: #C04D99;
$secondary-400: #B7338A;
$secondary-500: #A5006D;
$secondary-600: #840057;
$secondary-700: #630041;
$secondary-800: #530037;
$secondary-900: #310021;

// Danger colors
$danger-25: #FFF6F8;
$danger-50: #FFEFF3;
$danger-100: #FFE6EC;
$danger-200: #FFB3C7;
$danger-300: #FF80A2;
$danger-400: #FF3369;
$danger-500: #FF0044;
$danger-600: #CC0036;
$danger-700: #800022;
$danger-800: #4F0B06;
$danger-900: #4C0014;

// Warning colors
$warning-25: #FFFCF5;
$warning-50: #FFFAEB;
$warning-100: #FEF0C7;
$warning-200: #FEDF89;
$warning-300: #FDC660;
$warning-400: #FDC150;
$warning-500: #FDB022;
$warning-600: #ED9A00;
$warning-700: #CA8402;
$warning-800: #A26A02;
$warning-900: #794F01;

// Success colors
$success-25: #F6FEF9;
$success-50: #ECFDF3;
$success-100: #D1FADF;
$success-200: #A6F4C5;
$success-300: #6CE9A6;
$success-400: #32D583;
$success-500: #12B76A;
$success-600: #039855;
$success-700: #027A48;
$success-800: #05603A;
$success-900: #054F31;

// Gray colors
$gray-25: #FCFCFD;
$gray-50: #F9FAFB;
$gray-100: #F2F4F7;
$gray-200: #EAECF0;
$gray-300: #D0D5DD;
$gray-400: #98A2B3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1D2939;
$gray-900: #101828;

// Gradients
$gradient-primary: linear-gradient(100deg, $primary-500, #474C9E);
$gradient-primary-hover: linear-gradient(100deg, lighten($primary-500, 10%), lighten(#474C9E, 10%));