@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.content {
  width: 980px;

  &__block {
    display: flex;
    gap: 20px;
    margin-bottom: 80px;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: 640px) {
      flex-direction: row;
    }
    
    @include desktop {
      gap: 40px;
    }
  }
}