@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.update {
  max-width: 980px;
  border: 1px solid #C6C6C6;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;

  @include mobile {
    padding: 20px;
  }

  @include tablet {
    padding: 30px;
  }

  @include desktop {
    margin-bottom: 40px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: $primary-500;
    @include customizableText(700, 18px, 26px);

    @include tablet {
      @include customizableText(700, 26px, 32px);
    }
  }

  &__divider {
    height: 1px;
    background-color: #D9D9D9;
    margin: 30px 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 30px;

    @media (min-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
  }

  &__radio-label {
    color: $primary-500;
    margin-bottom: 25px;
    @include customizableText(500, 16px, 19.5px);
  }

  &__radio-buttons {
    display: flex;
    gap: 40px;
  }
}

.full-width {
  width: 100%;
  max-width: 510px;
}

.dob-button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  color: $primary-500;
  margin-bottom: 10px;
  @include customizableText(500, 16px, 20px);
}

.dob-button-custom {
  display: flex;
  align-items: flex-start;
  background-color: $gray-100;
  height: 50px;
  padding: 0 15px;
  border-radius: 16px;
  text-align: left;
  color: $gray-400;
  width: 100%;
  max-width: 510px;
  border: none;
  @include customizableText(500, 16px, 20px);

  &::placeholder {
    text-align: left;
    color: $gray-400;
  }
}

.selected-day {
  background-color: $primary !important;
}