@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.table {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin-bottom: 80px;
  justify-content: center;
  width: 100%;

  @include tablet {
    justify-content: flex-start;
  }

  @media (min-width: 1300px) {
    width: 980px;
  }
}

.table__cell {
  box-sizing: border-box;
  flex: 1 1 auto;
  max-width: 320px;
  margin: 0;

  @include mobile {
    flex: none;
    width: 320px;
  }

  @include tablet {
    width: 224px;
    margin: 0;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 100%;
  padding: 50px 0;
  background-color: $gray-50;

  &__text {
    margin-bottom: 30px;
    @include customizableText(600, 20px, 24px);
    color: $textBlack;
    text-align: center;
  }

  &__icon-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: -2px;
  }
}