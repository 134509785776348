@import "../../../../../styles/scss/variables";
@import "../../../../../styles/scss/mixins/typography";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 608px;
  width: 30%;
  min-width: 300px;
  background-color: $white;
  padding: 10px 25px;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}


.title {
  text-align: center;
  @include customizableText(500, 20px, 24px);
  color: $textBlack;
}

.subtitle {
  margin-top: 15px;
  text-align: center;
  @include descriptionTextBig;
  font-family: "Montserrat";
}

.buttons-wrapper {
  display: flex;
  justify-content: space-around;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  &__pickup {
    width: 300px;
    border-radius: 5px;
    padding: 10px 12px;
  }

  &__delivery {
    width: 300px;
    border-radius: 5px;
    margin-left: 20px;
    padding: 10px 12px;
  }

  &--active {
    background-color: $primary;
    border: 1px solid $primary;
  }
}

.big-buttons {
  & > button {
    @include customizableText(500, 28px, 34px);
    height: 70px;
  }
}

.autocomplete {
  position: relative;
  width: 100%;
  align-self: center;

  &__input {
    display: flex;
    text-align: center;

    @include commonText(500);
    color: $textBlack;

    width: 100%;

    border-radius: 5px;
    border: 1px solid $textBlack;
    padding: 13px 22px;

    text-overflow: ellipsis;
    margin-top: 10px;

    &::placeholder {
      color: $textBlack;
    }
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 100;

    position: absolute;
    top: 52px;
    left: 0;

    width: 100%;
    max-height: 270px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: $white;

    @include customizableText(500, 12px, 14px);
    color: $textBlack;

    border-radius: 5px;
    border: 1px solid $textBlack;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

  }

  &__list {
    width: 100%;
    padding: 5px 0;
    background-color: $white;

    & > button {
      width: 100%;
      text-align: start;
      padding: 5px 10px;

      &:hover {
        background-color: $lightSilver;
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.saved-addresses {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &__subtitle {
    text-align: center;
    @include customizableText(500, 20px, 24px);
    color: $textBlack;
    margin-bottom: 20px;
  }

  &__list {
    max-height: 300px;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid $lightGrey;
    border-radius: 5px;
    background-color: $white;
    width: 100%;
    min-height: 75px;
    max-height: 120px;
    box-sizing: border-box;

    &:hover {
      border-color: $primary;
    }

    &--selected {
      border-color: $primary;
    }
  }

  &__name {
    color: $textBlack;
    margin-bottom: 5px;
    text-align: left;
  }

  &__details {
    color: $grey;
    text-align: left;
  }
}

.provider-wrapper {
  height: 240px;
}

.additional-button {
  @include commonText(500);
  color: $textBlack;
  text-decoration: underline;
  margin-bottom: 30px;
}

.loading {
  height: 270px;

  &__title {
    @include additionalText;
  }
}

.not-found {
  @include commonText(500);
  color: $textBlack;
  text-align: center;
}
