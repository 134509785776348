@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.custom-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 50px;
    padding: 20px;
    @include additionalText;
  
    &.small {
      height: 50px;
    }
  
    &.large {
      height: 64px;
    }

    &.extra-small {
      height: 40px;
      padding: 15px;
      border-radius: 10px;
      @include customizableText(500, 14px, 18px);
    }
  
    &.primary {
      background: $gradient-primary;
      color: $white;
  
      &:hover:not(:disabled) {
        background: $gradient-primary-hover;
      }
  
      .icon {
        color: $white;
      }
    }
  
    &.secondary {
      background-color: $primary-25;
      color: $primary-500;
  
      &:hover:not(:disabled) {
        background-color: darken($primary-25, 10%);
      }
  
      .icon {
        color: $primary-500;
      }
    }

    &.danger {
      background-color: $danger-50;
      color: $danger-500;
  
      &:hover:not(:disabled) {
        background-color: darken($danger-50, 10%);
      }
  
      .icon {
        color: $danger-500;
      }
    }

    &:disabled {
      background-color: $gray-100;
      color: $gray-400;
      cursor: not-allowed;
  
      .icon {
        color: $gray-400;
      }
    }

    .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
      }

      &.loading {
        cursor: not-allowed;
    
        .content {
          opacity: 0;
        }
    
        .spinner-container {
          display: flex;
        }
    
        &.primary {
          background: $gradient-primary !important;
          color: $white;
    
          .icon {
            color: $white;
          }
        }
    
        &.secondary {
          background-color: $primary-25 !important;
          color: $primary-500;
    
          .icon {
            color: $primary-500;
          }
        }
    
        &.danger {
          background-color: $danger-50 !important;
          color: $danger-500;
    
          .icon {
            color: $danger-500;
          }
        }
      }
    
      .spinner-container {
        display: none;
        position: absolute;
      }

      .content {
        display: inline-flex;
        align-items: center;
        gap: 8px;
      }
  }