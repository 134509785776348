@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.items {
  max-width: 980px;
  padding: 12px;
  border: 1px solid $gray-300;
  border-radius: 20px;
  margin-bottom: 80px;

  @include mobile {
    padding: 20px;
  }

  &__title {
    @include customizableText(700, 20px, 26px);
    color: $primary-500;
    margin-bottom: 20px;

    @media (min-width: 760px) {
      @include customizableText(700, 26px, 32px);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
