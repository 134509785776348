@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.item {
  display: flex;
  flex-direction: row;
  height: auto;
  max-width: 100%;
  align-items: flex-start;
  text-decoration: none;
  color: inherit;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-right: 20px;
    width: 100px !important;
    height: 100px !important;
    padding: 18px 16px;
    border: 1px solid $gray-300;
    border-radius: 16px;

    img {
      width: 68px;
      height: 64px;
      object-fit: contain;
    }

    .wrapper-no-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-50;
      border-radius: 16px;
      width: 68px;
      height: 68px;

      img {
        max-width: 48px;
        max-height: 44px;
        object-fit: contain;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - 120px);
  }

  &__price-row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 340px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__price-separator {
    margin: 0 5px;

    @include mobile {
      margin: 0 10px;
    }
  }

  &__name {
    @include customizableText(500, 14px, 16px);
    color: $textBlack;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 6px;
    max-width: 590px;

    @include mobile {
      @include customizableText(500, 18px, 22px);
    }
  }

  &__volume {
    color: $gray-400;
    @include customizableText(500, 13px, 15px);

    @include mobile {
      @include customizableText(500, 16px, 19.5px);
    }
  }

  &__price {
    white-space: nowrap;
    color: $textBlack;
    @include customizableText(600, 16px, 20px);
    margin-bottom: 10px;

    @media (max-width: 340px) {
      margin-bottom: 0px;
    }

    @include mobile {
      @include customizableText(600, 20px, 24px);
    }
  }

  &__price-total {
    white-space: nowrap;
    color: $textBlack;
    @include customizableText(700, 16px, 20px);

    @include mobile {
      @include customizableText(700, 20px, 24px);
    }
  }
}
