@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.card {
  position: relative;
  width: 100%;
  max-width: 320px;
  height: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @include tablet {
    max-width: 224px;
  }

  &__heart_icon-wrapper{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }

  &.horizontal {
    flex-direction: row;
    height: auto;
    max-width: 100%;
    align-items: flex-start;
    
    .image {
      margin-bottom: 0;
      margin-right: 20px;
      width: 100px !important;
      height: 100px !important;
      padding: 18px 16px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
  
      img {
        width: 68px;
        height: 64px;
        object-fit: contain;
      }

      .wrapper-no-image {
        height: 65px;
        border-radius: 10px;
        background-color: $gray-50;
  
        img {
          max-width: 48px;
          max-height: 44px;
          object-fit: contain;
        }
      }
    }

    .details {
      align-items: flex-start;
      width: calc(100% - 120px);

      &__price,
      &__name,
      &__volume {
        max-width: none;
      }

      &__price {
        height: 20px;
        @include customizableText(600, 16px, 20px);
        @include tablet {
          height: 29px;
          @include customizableText(700, 24px, 29px);
        }
      }

      &__name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: auto;
        @include customizableText(500, 16px, 20px);
        @include tablet {
          @include customizableText(500, 20px, 24.38px);
        }
      }

      &__volume {
        @include customizableText(500, 14px, 18px);
        @include tablet {
          @include customizableText(600, 18px, 21.94px);
        }
      }

      &__sale {
        margin-bottom: 6px;
        @include tablet {
          margin-bottom: 10px;
        }
        &__percent {
          padding: 3px 8px;
          gap: 5px;
          @include customizableText(500, 14px, 15px);

          @include tablet {
            padding: 4px 10px;
            gap: 10px;
            @include customizableText(700, 18px, 21.94px);
          }
        }
      }
    }
  }

  &.mime {
    width: 100px;
    height: calc(100% - 120px);
    .image {
      width: 100px;
      height: 100px;
      padding: 18px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;

      img {
        width: 68px;
        height: 64px;
        object-fit: contain;
      }

      .wrapper-no-image {
        height: 65px;
        border-radius: 10px;
        background-color: $gray-50;
  
        img {
          max-width: 48px;
          max-height: 44px;
          object-fit: contain;
        }
      }
    }

    .details {
      display: none;
    }
  }
}

.unavailable {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 5;
  }
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
  height: 224px;
  border: 1px solid $gainsboro;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 20px;

  @include tablet {
    max-width: 224px;
  }

  img {
    max-width: 160px;
    max-height: 154px;
    object-fit: contain;
  }

  .wrapper-no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-50;
    width: 220px;
    height: 180px;
    border-radius: 16px;

    @include tablet {
      width: 180px;
    }
  }

  .no-image {
    max-width: 124px;
    max-height: 124px;
    object-fit: contain;
  }
}


.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__price {
    width: 100%;
    max-width: 320px;
    height: 29px;
    @include customizableText(700, 22px, 26px);
    text-align: left;
    color: $primary-500;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      max-width: 224px;
    }
  }

  &__sale {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__percent {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      gap: 10px;
      border-radius: 70px;
      background: $danger-500;
      opacity: 1;
      @include customizableText(600, 18px, 21.94px);
      text-align: left;
      color: $white;
    }
  }

  &__old-price {
    @include customizableText(600, 18px, 21.94px);
    text-align: left;
    color: #a6a6a6;
    text-decoration: line-through;
    margin-left: 10px;
  }

  &__name {
    width: 100%;
    max-width: 320px;
    @include customizableText(500, 20px, 24.38px);
    text-align: left;
    color: $primary-500;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-right: 10px;

    @include tablet {
      max-width: 224px;
      -webkit-line-clamp: 3;
    }
  }

  &__volume {
    width: 224px;
    @include customizableText(600, 18px, 21.94px);
    text-align: left;
    color: $gray-400;
  }

  &__separator{
    display: inline-block;
    text-align: center;
    width: 26px;
    color: $lighterGrey;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    margin: 0 5px;

    @include tablet {
      width: 20px;
    }
  }

  &__separator::before {
    content: "";
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 3px;
    background-color: $lighterGrey;
    vertical-align: middle;

    @include tablet {
      width: 18px;
    }
  }
}