@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.orders-preview {
  display: flex;
  flex-direction: column;
  background-color: $gray-200;
  padding: 10px;
  border-radius: 20px;
  width: 320px;
  min-height: 436px;
  flex-shrink: 0;
  
  @include tablet {
    padding: 20px;
  }
  
  @include desktop {
    padding: 30px;
  }
  
  @media (min-width: 700px) {
    height: 500px;
  }

  @media (max-width: 700px) {
    width: 100%;
    max-width: 680px;
    min-width: 290px;
  }

  &__title {
    @include customizableText(700, 24px, 30px);
    color: $primary-500;
    margin-bottom: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
  }

  &__list {
    margin-bottom: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 126px;
    padding: 24px 20px;
    background-color: white;
    border: 1px solid $gray-300;
    border-radius: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    color: $primary-500;
  }

  &__item-id {
    @include customizableText(700, 18px, 22px);
    margin-bottom: 2px;
    margin-top: 10px;
  }

  &__item-status {
    @include customizableText(500, 16px, 20px);
    color: $gray-400;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  &__empty {
    @include customizableText(600, 20px, 24px);
    color: $gray-400;
    margin-bottom: 20px;
    align-self: flex-start;
  }

  &__button-container {
    display: flex;
    justify-content: center;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}