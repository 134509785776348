@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.addresses {
  max-width: 980px;
  border: 1px solid #C6C6C6;
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 20px;

  @include mobile {
    padding: 20px;
  }

  @include tablet {
    padding: 30px;
  }

  @include desktop {
    margin-bottom: 40px;
  }


  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: $primary-500;
    @include customizableText(700, 18px, 26px);

    @include tablet {
      @include customizableText(700, 26px, 32px);
    }
  }
  
  &__divider {
    height: 1px;
    background-color: #D9D9D9;
    margin: 30px 0 45px 0;
  }

  .full-width {
    width: 100%;
  }
}

.addres {
  width: 100%;

    &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    }

    &__title {
      @include customizableText(700, 20px, 24px);
      color: $primary-500;

      @include tablet {
        @include customizableText(700, 24px, 29px);
      }
    }

    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 30px;
  
      @media (min-width: 641px) {
        flex-direction: row;
      }
    }

    &__additional-inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;

      @include tablet {
        gap: 30px;
      }
    }

    .half-width {
      width: calc(50% - 10px);

      @include tablet {
        width: calc(50% - 15px);
      }
    }
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.dropdown-item--name {
  @include customizableText(500, 14px, 16px);
  color: $primary-500;
}

.dropdown-item--region {
  display: block;
  @include customizableText(400, 12px, 14px);
  color: $gray-500;
  margin-top: 5px;
}


.map-container {
  position: relative;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;

  &__map {
    width: 100%;
    height: 400px;
    border-radius: 10px;
  }

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 11;
  }

  &__placemark {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    background: url('../../../../../assets/images/pointer.png') no-repeat center center;
    background-size: contain;
    z-index: 11;
    pointer-events: none;
  }

  &__address {
    position: absolute;
    top: calc(50% - 60px);
    left: 50%;
    transform: translateX(-50%);
    background-color: $gray-100;
    color: $primary-500;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    z-index: 1000;
    @include customizableText(500, 14px, 16px);
  }
}