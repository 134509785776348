@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 244px;
  width: 100%;

  border-radius: 5px;
  padding: 16px 26px;

  background-color: $white;
  box-shadow: 0px 0px 9px 2px rgba(46, 46, 46, 0.12);

  // @include desktop {
  //   position: absolute;
  //   left: 70%;
  // }
}

.title {
  @include customizableText(500, 12px, 12px);
  color: $textBlack;

  margin-bottom: 16px;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.button {
  height: 26px;

  padding: 6px 36px;

  @include customizableText(500, 12px, 12px);
}

.background {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);

  z-index: -1;
}