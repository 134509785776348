@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.navigation {
  display: flex;
  flex-direction: column;
  width: 230px;
}

.navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation li {
  margin-bottom: 30px;
}

.navigation a {
  text-decoration: none;
  @include customizableText(600, 20px, 24px);
  color: $primary-500;
}

.navigation a.active {
  color: $gray-400;
}

.navigation .profile-link {
  display: flex;
  align-items: center;
  color: $primary-500;
}

.navigation .separator {
  height: 1px;
  background-color: $gray-200;
  margin: 30px 0;
}

.navigation .arrow {
  margin-right: 5px;
}