@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.content {
  width: 980px;

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70dvh;
  }
}

.info {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;

  @include mobile {
    gap: 30px;
  }

  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 540px) {
    flex-direction: column;
  }
}

.custom-title-class {
  margin: 10px 0 20px 0 !important;

  @include tablet {
    margin: 0 0 20px 0 !important;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 100%;
  padding: 30px;
  background-color: $gray-50;
  margin-bottom: 80px;

  &__text {
    margin-bottom: 30px;
    @include customizableText(600, 20px, 24px);
    color: $primary-500;
    text-align: center;
  }
}
