@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.addresses__divider {
  height: 1px;
  background-color: #D9D9D9;
  margin: 30px 0 45px 0;
}

.full-width {
  width: 100%;
}

.addres {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title {
    @include customizableText(700, 20px, 24px);
      color: $primary-500;

      @include tablet {
        @include customizableText(700, 24px, 29px);
      }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: 600px) {
      flex-direction: row;
    }
  }
}