@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.order-item {
  display: block;
  width: 100%;
  border: 1px solid $gray-300;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  color: inherit;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__header-left,
  &__header-right {
    display: flex;
    flex-direction: column;
  }

  &__header-right {
    gap: 2px;
    align-items: flex-end;
  }

  &__header-left {
    gap: 10px;
  }

  &__id {
    color: $primary-500;
    @include customizableText(700, 20px, 26px);

    @include tablet {
      @include customizableText(700, 26px, 32px);
    }
  }

  &__sum-label {
    color: $primary-500;
    @include customizableText(600, 15px, 18.5px);

    @include tablet {
      @include customizableText(600, 16px, 19.5px);
    }
  }

  &__sum {
    color: $primary-500;
    @include customizableText(700, 22px, 28px);

    @include tablet {
      @include customizableText(700, 26px, 32px);
    }
  }

  &__date {
    white-space: nowrap;
    color: $gray-400;
    @include customizableText(600, 15px, 18.5px);

    @include tablet {
      @include customizableText(600, 16px, 19.5px);
    }
  }

  &__divider {
    height: 1px;
    background-color: $gray-200;
    margin: 20px 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @include mobile {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

}