@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $gray-300;
  border-radius: 20px;
  max-width: 500px;
  padding: 12px;
  gap: 20px;

  @include mobile {
    padding: 20px;
  }

  @media (max-width: 540px) {
    max-width: 540px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    color: $primary-500;
    @include customizableText(700, 20px, 26px);

    @media (min-width: 760px) {
      @include customizableText(700, 26px, 32px);
    }
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    @include customizableText(500, 16px, 19.5px);
    color: $gray-500;
  }

  &__value {
    white-space: nowrap;
    @include customizableText(600, 16px, 19.5px);
    color: $primary-500;

    &--success {
        color: $success-600;
      }
  
      &--fail {
        color: $danger-500;
      }
  
      &--pending {
        color: $warning-300;
      }
  }
}

.wrapper {
  margin-top: auto;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    color: $primary-500;
    @include customizableText(700, 14px, 18px);

    @media (min-width: 760px) {
      @include customizableText(700, 18px, 22px);
    }
  }

  &__value {
    white-space: nowrap;
    color: $primary-500;
    @include customizableText(700, 20px, 26px);

    @media (min-width: 760px) {
      @include customizableText(700, 26px, 32px);
    }
  }
}

.divider {
  border-top: 1px solid $gray-200;
  margin: 20px 0;
}
