@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 12;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $lightSilver;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $textBlack;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($textBlack, 10%);
  }
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}

.dropdown-item--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  z-index: 11;
}

.dropdown-item--result {
  padding: 10px;
  @include customizableText(500, 14px, 16px);
  color: $gray-500;
}
