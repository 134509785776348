@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.order {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px;
  border: 1px solid $gray-300;
  border-radius: 20px;
  max-width: 450px;

  @include mobile {
    padding: 20px;
  }

  @media (max-width: 540px) {
    max-width: 540px;
  }

  &__title {
    color: $primary-500;
    @include customizableText(700, 20px, 26px);

    @media (min-width: 760px) {
      @include customizableText(700, 26px, 32px);
    }
  }

  .block {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    &__icon {
      margin-top: 4px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__label {
      @include customizableText(700, 14px, 18px);
      color: $primary-500;

      @media (min-width: 760px) {
        @include customizableText(700, 18px, 22px);
      }
    }

    &__value {
      color: $gray-500;
      @include customizableText(500, 13px, 15px);

      @media (min-width: 760px) {
        @include customizableText(500, 16px, 19.5px);
      }
    }
  }
}
