@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.cart-product-description {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__name {
    @include customizableText(500, 16px, 19.5px);
    color: $textBlack;
    margin-bottom: 4px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__short-description {
    @include customizableText(500, 16px, 19.5px);
    color: $textLightGrey;
    margin-bottom: 7px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__articul {
    @include customizableText(500, 12px, 15px);
    color: $textLightGrey;
    margin-bottom: 13px;
  }

  &__price-and-info-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    min-height: 15px;
  }

  &__price {
    @include customizableText(500, 12px, 15px);
    color: $textLightGrey;
  }
}
