@import "/src/styles/scss/variables";
@import "/src/styles/scss/mixins/typography";

.radio {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $primary-25;
  }
  
  &__indicator.checked {
    background-color: $primary-500;
  }
  
  &__inner {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $white;
  }
  
  &__label {
    @include commonText(400);
    margin-left: 8px;
    color: inherit;
  }

  input:disabled + &__indicator {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  input:disabled ~ .radio__label {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

