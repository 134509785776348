@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";


.personal-data {
  border: 1px solid $gray-300;
  padding: 10px;
  border-radius: 20px;
  max-width: 1260px;
  min-width: 290px;
  width: 100%;
  margin: 0 auto;

  @include tablet {
    padding: 20px;
  }

  @include desktop {
    padding: 30px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    &__title {
      @include customizableText(700, 24px, 29px);
    }

    @media (max-width: 470px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .divider {
    height: 1px;
    background-color: $gray-200;
    margin: 30px 0;
  }

  .section-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 470px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .info {
    display: flex;
    align-items: center;

    &__avatar-wrapper {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: $primary-25;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      flex-shrink: 0;

      @include tablet {
        margin-right: 20px;
        width: 100px;
        height: 100px;
      }

      @include desktop {
        margin-right: 30px;
        width: 150px;
        height: 150px;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 5px;

      @include tablet {
        gap: 10px;
      }
    }

    &__name,
    &__email,
    &__phone {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: wrap;
      word-break: break-word;
      max-width: 100%;
    }

    &__name {
      color: $primary-500;
      @include customizableText(600, 16px, 19px);

      @include tablet {
        @include customizableText(600, 20px, 24px);
      }
    }

    &__email {
      color: $gray-400;
      @include customizableText(500, 12px, 15px);

      @include tablet {
        @include customizableText(600, 16px, 19.5px);
      }
    }

    &__phone {
      color: $primary-500;
      @include customizableText(400, 12px, 15px);

      @include tablet {
        @include customizableText(500, 16px, 19.5px);
      }
    }
  }

  .section-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 30px;
    align-items: start;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .column {
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-rows: minmax(auto, max-content);

    &__label {
      color: $gray-400;
      margin-bottom: 6px;
      @include customizableText(500, 12px, 15px);

      @include tablet {
        @include customizableText(600, 16px, 19.5px);
      }
    }

    &__value {
      color: $primary-500;
      @include customizableText(600, 14px, 17px);

      @include tablet {
        @include customizableText(700, 18px, 22px);
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .social-media {
    &__title {
      color: $gray-400;
      margin-bottom: 6px;
      @include customizableText(500, 12px, 15px);

      @include tablet {
        @include customizableText(600, 16px, 19.5px);
      }
    }
  
    &__items {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  
    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  
    &__icon-wrapper {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__icon {
      width: 100%;
      height: 100%;
    }
  
    &__icon--bw {
      filter: grayscale(100%);
    }
  }
  
  .password {
    &__title {
      color: $gray-400;
      margin-bottom: 6px;
      @include customizableText(500, 12px, 15px);

      @include tablet {
        @include customizableText(600, 16px, 19.5px);
      }
    }
  
    &__dots {
      display: flex;
      gap: 5px;

      @include tablet {
        gap: 10px;
      }
    }
  
    &__dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $primary-500;

      @include tablet {
        width: 10px;
        height: 10px;
      }
    }
  }

}






