.image {
  filter: blur(0px);
  transition: filter 300ms linear, opacity 300ms linear;
  opacity: 0;

  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 200% 200%;
  animation: gradient 3.6s infinite;

  &--first-transition {
    background-image: none;
    animation: none;
    filter: blur(4px);
  }

  &--second-transition {
    background-image: none;
    animation: none;
    filter: blur(1px);
  }

  &--third-transition {
    background-image: none;
    animation: none;
    filter: blur(0px);
  }

  &--loaded {
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position-x: 100%;
  }

  100% {
    background-position-x: -100%;
  }
}
