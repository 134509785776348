@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.password {
  border: 1px solid #C6C6C6;
  border-radius: 20px;
  padding: 10px;
  flex: 1 1 290px;
  // flex: 1;
  // min-width: 290px;
  max-width: 470px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include mobile {
    padding: 20px;
  }

  @include tablet {
    padding: 30px;
  }

  &__title {
    color: $primary-500;
    @include customizableText(700, 22px, 30px);

    @include tablet {
      @include customizableText(700, 26px, 32px);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__input {
    width: 100%;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
  
  // @media (max-width: 1023px) {
  //   max-width: 100%;
  // }

  // @media (max-width: 910px) {
  //   max-width: 470px;
  // }
}