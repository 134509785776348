@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.card {
  width: 100%;
  max-width: 320px;
  height: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;

  @include tablet {
    width: 230px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &.profile {
    margin-bottom: 0;
    @include tablet {
      width: 224px;
    }
  }

  &.mime {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .custom-avatar {
      width: 100px;
      height: 100px;
    }

    &__details {
      display: none;
    }
  }
}

.custom-avatar {
  max-width: 320px;
  max-height: 230px;
  width: 320px;
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0;

  @include tablet {
    width: 230px;
  }

  &.profile {
    @include tablet {
      width: 224px;
    }
  }

}
