@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.social {
  border: 1px solid #C6C6C6;
  border-radius: 20px;
  padding: 10px;
  flex: 1 1 290px;
  max-width: 470px;

  @include mobile {
    padding: 20px;
  }

  @include desktop {
    padding: 30px;
  }

  &__title {
    margin-bottom: 30px;
    color: $primary-500;
    @include customizableText(700, 22px, 30px);

    @include tablet {
      @include customizableText(700, 26px, 32px);
    }
  }

  @media (max-width: 640px) {
    flex: 1 1 auto;
    max-width: 100%;
  }
  

  &__items {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  &__type {
    display: flex;
    align-items: center;
  }
  
  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;

    @include mobile {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
  
  &__subtitle {
    @include customizableText(700, 14px, 18px);

    @include mobile {
      @include customizableText(700, 18px, 22px);
    }
  }
}