@import "/src/styles/scss/variables";
@import "/src/styles/scss/breakpoints";
@import "/src/styles/scss/mixins/typography";

.faivorite-prewiev {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $gray-300;
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  max-width: 900px;
  min-width: 290px;
  min-height: 406px;

  @media (min-width: 700px) {
    height: 500px;
  }

  @include tablet {
    padding: 20px;
  }

  @include desktop {
    padding: 30px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;

    @media (min-width: 460px) and (max-width: 700px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: 701px) and (max-width: 809px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (min-width: 809px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    @media (min-width: 460px) and (max-width: 700px) {
      margin-bottom: 0;
    }

    @media (min-width: 701px) and (max-width: 808px) {
      margin-bottom: 16px;
    }

    @media (min-width: 811px) {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 6px;
    @include customizableText(700, 24px, 30px);
    color: $primary-500;
  }

  &__quantity {
    @include customizableText(700, 16px, 19.5px);
    color: $gray-400;
  }

  &__product-cards {
    display: flex;
    flex-direction: column;
  }

  &__divider {
    border-bottom: 1px solid $gray-200;
    margin: 20px 0;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    width: 100%;
    flex-grow: 1;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-50;
      border-radius: 16px;
      z-index: -1;
      margin-bottom: 0px;
    }
  }

  &__empty-text {
    margin-bottom: 30px;
    @include customizableText(600, 20px, 24px);
    color: $textBlack;
    text-align: center;
  }

  &__icon-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: -2px;
  }
}
